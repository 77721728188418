/* eslint-disable react/no-unknown-property */
import PageNotFound from 'components/widgets/error/page-not-found';
import * as React from 'react';

export default function Custom404() {
  return (
    <>
      <style global jsx>
        {`
          #__next {
            height: 100vh;
            justify-content: center;
          }
        `}
      </style>
      <PageNotFound />
    </>
  );
}
